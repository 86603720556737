"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { UserContext } from "./UserContext";
import { celebApi } from "@/services/api";

export const SocketContext = createContext<any>(null);

const ENDPOINT = celebApi ?? ""; // Use your server's IP and port

export const SocketProvider = ({ children }: any) => {
  const { currentUser, userToken } = useContext(UserContext);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const newSocket: any = socketIOClient(ENDPOINT, {
        query: { token: userToken },
      });
      setSocket(newSocket);

      return () => newSocket.close();
    } else {
      setSocket(null);
    }
  }, [currentUser]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;

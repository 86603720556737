import { PhotoI } from "@/interfaces/Galleries";
const DefaultUserAvata = "../../assets/images/placeholder-image.png";
export function sortPhotos(photos: PhotoI[]) {
  const data = photos
    .filter((element) => element.ifVerify === 1)
    .sort((a, b) => {
      if (a.year !== b.year) {
        return +a.year - +b.year;
      }
      if (a.month !== b.month) {
        return +a.month - +b.month;
      }
      return +a.day - +b.day;
    });
  return data;
}
export function groupBy(arr: Array<any>, property: string) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export const handleImageError = (e: any) => {
  e.target.onerror = null;
  e.target.src = DefaultUserAvata;
};

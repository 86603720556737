import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchInput from "../SearchInput/SearchInput";
import CustomSpinner from "@/components/CustomSpinner/CustomSpinner";
import ChatCard from "../ChatCard/ChatCard";
import { useChatContext } from "@/context/InboxContext";
import { useUserContext } from "@/context/UserContext";
const EditIcon = "../../assets/images/icons/edit-icon.svg";
const DotsIcon = "../../assets/images/icons/dots-icon.svg";
function RecentsChats() {
  const [searchText, setSearchText] = useState<string>("");
  const { currentUser } = useUserContext();

  const { loading, setLoading, setChatView, chats } = useChatContext();
  const getChats = (reset = false, notSkip = false) => {};

  return (
    <>
      <div className="header">
        <h2>Inbox</h2>
        <div className="btns-container right">
          <button className="btn optional-action">
            <img src={DotsIcon} />
          </button>
          <button
            className="btn optional-action"
            onClick={() => setChatView(1)}
          >
            <img src={EditIcon} />
            <div className="description left">
              <p>New message</p>
            </div>
          </button>
        </div>
      </div>
      <div className="content-container">
        <div className="search-container">
          <h3 className="mb-2">Recent chats</h3>
          <SearchInput
            value={searchText}
            setValue={setSearchText}
            placeHolder="Search in recents"
          />
        </div>
        <div className="chats-container">
          <h4>Recents chats</h4>
          {loading && (
            <CustomSpinner className="m-auto" size={8} borderWidth={4} />
          )}
          {!loading && chats.length && (
            <InfiniteScroll
              next={getChats}
              hasMore={false}
              loader={<CustomSpinner size={2} borderWidth={1} />}
              dataLength={chats.length}
            >
              {chats.map((chat) => {
                if (chat.lastMessages && chat.lastMessages.length) {
                  return (
                    <ChatCard
                      chat={chat}
                      key={chat._id}
                      user={
                        chat.participants.filter(
                          (e) => e._id !== currentUser!._id
                        )[0]
                      }
                    />
                  );
                }
              })}
            </InfiniteScroll>
          )}
          {!loading && chats.length === 0 && <h3>No chats found</h3>}
        </div>
      </div>
    </>
  );
}

export default RecentsChats;

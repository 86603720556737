"use client";
import { useThemeContext } from "@/context/ThemeContext";
import "./CustomSpinner.scss";

interface CustomSpinnerPropsI {
  size: number;
  borderWidth: number;
  className?: string;
}

function CustomSpinner(props: CustomSpinnerPropsI) {
  const { size, borderWidth, className } = props;
  const { theme } = useThemeContext();
  return (
    <div
      className={
        `custom-spinner-component spinner-border ${className ?? ""} ` +
        (theme === "dark" ? "text-light" : "text-dark")
      }
      style={{
        height: `${size}rem`,
        width: `${size}rem`,
        borderWidth: borderWidth,
      }}
      role="status"
    >
      <span className="sr-only"></span>
    </div>
  );
}

export default CustomSpinner;

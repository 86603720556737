import { UsersI } from "@/interfaces/Admin";
import "./ChatCard.scss";
import { handleImageError } from "@/utils/GalleryFunctions";
import { useChatContext } from "@/context/InboxContext";
import { newChat } from "@/services/InboxService";
import { useUserContext } from "@/context/UserContext";
import { notifyError } from "@/utils/NotificationFunctions";
import { ChatI } from "@/interfaces/Inbox";
import { useContext } from "react";
import { SocketContext } from "@/context/SocketContext";

interface ChatCardPropsI {
  user: UsersI;
  chat?: ChatI;
}

function ChatCard(props: ChatCardPropsI) {
  const { chat, user } = props;

  const { setChatView, setActiveChat } = useChatContext();
  const { currentUser, userToken } = useUserContext();

  const socket = useContext(SocketContext);

  const createChat = async () => {
    newChat(user._id, userToken).then((res) => {
      if (res && res.status === 200) {
        setActiveChat(res.data.chat);
        socket.emit("create-chat", res.data.chat._id);
      } else {
        notifyError("An error has ocurred");
      }
    });
  };

  const handleActiveChat = () => {
    setActiveChat(chat);
  };

  return (
    <div
      className="chat-card-component-main-container"
      onClick={chat ? handleActiveChat : createChat}
    >
      {chat?.inboxId && (
        <h3 className="inbox-title fs-4 bg-card-black text-light">
          {chat.inboxId.title}
        </h3>
      )}
      <img src={user?.avatar} onError={handleImageError} alt="avatar" />
      <div className="info">
        <h3>
          {user?.name} {user?.admin > 0 ? "(Moderator)" : ""}
        </h3>
        {chat && (
          <p>{chat.lastMessages?.length ? chat.lastMessages[0].message : ""}</p>
        )}
      </div>
      {chat && chat.unReadMessages > 0 && (
        <div className="unread-circle">{chat.unReadMessages}</div>
      )}
    </div>
  );
}

export default ChatCard;

"use client";
import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SocketContext } from "./SocketContext";
import { ChatI } from "@/interfaces/Inbox";
import { useUserContext } from "./UserContext";
import { fetchChats } from "@/services/InboxService";
import { notifyError } from "@/utils/NotificationFunctions";
import { newChatByInbox } from "@/services/admin/AdminService";
import React from "react";
import { AdminChatCardI } from "@/interfaces/Admin";

interface ChatContextI {
  chats: Array<ChatI>;
  getChats: () => void;
  activeChat: ChatI | null;
  chatView: number;
  setChatView: React.Dispatch<SetStateAction<number>>;
  setMessageSupport: React.Dispatch<SetStateAction<any>>;
  loading: boolean;
  MessageSupport: any;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  setActiveChat: React.Dispatch<SetStateAction<any>>;
  pendingChats: number;
  setPendingChats: React.Dispatch<SetStateAction<number>>;
  openInbox: boolean;
  setOpenInbox: React.Dispatch<SetStateAction<boolean>>;
  createNewChatByInbox: (param: string, inboxId: string) => Promise<void>;
  openChat: (
    chatId: string,
    adminView?: boolean,
    chat?: AdminChatCardI
  ) => void;
  inboxContainerRef: React.RefObject<any>;
}

interface ChatContextProviderPropsI {
  children: ReactNode;
}

export const ChatContext = createContext<ChatContextI>({
  chats: [],
  getChats: () => {},
  activeChat: null,
  chatView: 0,
  setChatView: () => {},
  loading: true,
  setLoading: () => {},
  setActiveChat: () => {},
  setMessageSupport: () => {},
  MessageSupport: "",
  pendingChats: 0,
  setPendingChats: () => {},
  openInbox: false,
  setOpenInbox: () => {},
  createNewChatByInbox: async () => {},
  openChat: (chatId, adminView?, chat?) => {},
  inboxContainerRef: React.createRef(),
});

export function useChatContext() {
  return useContext(ChatContext);
}

export const ChatContextProvider = ({
  children,
}: ChatContextProviderPropsI) => {
  const [chats, setChats] = useState<Array<any>>([]);
  const [activeChat, setActiveChat] = useState(null);
  const socket = useContext(SocketContext);
  const [chatView, setChatView] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { userToken, currentUser } = useUserContext();
  const [loadingChats, setLoadingChats] = useState<boolean>(true);
  const [pendingChats, setPendingChats] = useState<number>(0);
  const [openInbox, setOpenInbox] = useState<boolean>(false);
  const inboxContainerRef = useRef<any>(null);
  const [MessageSupport, setMessageSupport] = useState({
    message: "",
    idUser: "",
  });

  const getChats = async (chatId = "") => {
    setLoadingChats(true);
    fetchChats(userToken).then((res) => {
      if (res && res.status === 200) {
        const tempChats = [...res.data.chats];
        setChats(tempChats);
        setPendingChats(tempChats.filter((e) => e.unReadMessages > 0).length);
        if (chatId !== "") {
          setActiveChat(tempChats.filter((e) => e._id === chatId)[0]);
          setOpenInbox(true);
        }
        setLoadingChats(false);
        setLoading(false);
      }
    });
  };

  const updateChats = (message: any, chat?: ChatI) => {
    let temp = [...chats];
    if (chat) {
      // If the chat argument is provided, add it to the list
      temp = [...temp, chat];
    }
    const index = temp.findIndex((e) => e._id === message.chat);
    if (index !== -1 && activeChat === null) {
      temp[index].unReadMessages = temp[index].unReadMessages + 1;
      temp[index].lastMessages = [message];

      setPendingChats(temp.filter((e) => e.unReadMessages > 0).length);
      setChats([...temp]);
    }
  };

  const createNewChatByInbox = async (
    userId: string,
    inboxId: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      newChatByInbox(userId, inboxId, userToken)
        .then((res) => {
          if (res && res.status === 200) {
            socket.emit("create-chat", res.data.chat._id);
            getChats(res.data.chat._id);
            resolve();
          }
        })
        .catch((err) => {
          notifyError(err.response);
          reject(err);
        });
    });
  };

  const openChat = (
    chatId: string,
    adminView: boolean = false,
    chat?: AdminChatCardI
  ) => {
    if (chatId !== null) {
      const tempActiveChat = chats.filter((e) => e._id === chatId);
      console.log(tempActiveChat);
      if (tempActiveChat.length === 1) {
        console.log(tempActiveChat[0]);
        setActiveChat(tempActiveChat[0]);
        setOpenInbox(true);
      } else if (!tempActiveChat.length && adminView && chat) {
        console.log("admin view");
        setActiveChat(chat as any);
        setOpenInbox(true);
      } else {
        notifyError("There is no chat");
      }
    }
  };

  useEffect(() => {
    // Fetch chats from server when component mounts
    if (chatView === 0 && currentUser) {
      getChats();
    }
  }, [chatView, currentUser]);

  useEffect(() => {
    if (socket && !loadingChats) {
      // Listen for new messages
      socket.on("chat-created", (chat: ChatI) => {
        // Here, `chat` is the new chat object sent by the server
        // You can then add this new chat to your state or perform any other action

        // Add the new chat to the state
        setChats((prevChats) => [...prevChats, chat]);

        // Emit event to listen to the new chat
        socket.emit("listen-new-chat", chat._id);

        // Listen for the first message of the new chat
        socket.on("receive-message", (message: any) => {
          // Update the chat with the new message
          updateChats(message, chat);
        });
      });

      // Listen for new messages in existing chats
      socket.on("receive-message", (message: any) => {
        // Update messages state to include the new message
        updateChats(message);
      });

      // Remember to cleanup when component unmounts
      return () => {
        socket.off("receive-message");
        socket.off("chat-created");
      };
    }
  }, [socket, loadingChats]);

  useEffect(() => {
    if (activeChat !== null) {
      setChatView(2);
    } else {
      setChatView(0);
    }
  }, [activeChat]);

  const values = {
    getChats,
    chats,
    activeChat,
    chatView,
    setChatView,
    loading,
    setLoading,
    setActiveChat,
    pendingChats,
    setPendingChats,
    openInbox,
    setOpenInbox,
    createNewChatByInbox,
    openChat,
    setMessageSupport,
    MessageSupport,
    inboxContainerRef,
  };

  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export default ChatContextProvider;

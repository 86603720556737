import "./Chat.scss";
import { useChatContext } from "@/context/InboxContext";
import { useContext, useEffect, useState } from "react";
import { UsersI } from "@/interfaces/Admin";
import { useUserContext } from "@/context/UserContext";
import { object, string } from "yup";
import { useFormik } from "formik";
import { fetchMessages, seenAllByChat } from "@/services/InboxService";
import { handleImageError } from "@/utils/GalleryFunctions";
import { SocketContext } from "@/context/SocketContext";
import { useRouter } from "next/navigation";

const BackArrow = "../../assets/images/icons/arrow-left-icon.svg";
const DotsIcon = "../../assets/images/icons/dots-icon.svg";
const MessageSchema = object({
  message: string().required(),
});

function Chat() {
  const { setChatView, activeChat, setActiveChat, MessageSupport } =
    useChatContext();
  const [recipient, setRecipient] = useState<UsersI | null>(null);
  const { currentUser, userToken } = useUserContext();
  const [messages, setMessages] = useState<Array<any>>([]);
  const router = useRouter();

  const socket = useContext(SocketContext);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: MessageSchema,
    onSubmit: async (values) => {
      const messageData = {
        message: values.message,
        sender: currentUser!._id,
        chat: activeChat!._id,
      };

      socket.emit("send-message", messageData);
      formik.resetForm();
    },
  });

  const navigateProfile = (myProfile: boolean) => {
    if (!myProfile && recipient) {
      router.push("/profile?user=" + recipient.name);
    } else {
      router.push("/profile");
    }
  };

  const getMessages = async () => {
    fetchMessages(activeChat!._id, userToken).then((res) => {
      if (res && res.status === 200) {
        setMessages(res.data.messages);
      }
    });
  };

  const seenAll = async () => {
    seenAllByChat(activeChat!._id, userToken).then((res) => {});
  };
  useEffect(() => {
    if (activeChat !== null) {
      getMessages();
      seenAll();
    }
  }, [activeChat]);

  useEffect(() => {
    if (socket == null) return;

    // Listen for incoming messages
    socket.on("receive-message", (incomingMessage: any) => {
      console.log(incomingMessage);
      if (incomingMessage.chat === activeChat!._id) {
        // If the incoming message belongs to the current chat, add it to the messages
        setMessages((messages: any) => [incomingMessage, ...messages]);
      }
    });

    // Clean up the effect by removing the listener
    return () => socket.off("receive-message");
  }, [socket, activeChat]);

  useEffect(() => {
    if (activeChat !== null) {
      setRecipient(
        activeChat.participants.filter((e) => e._id !== currentUser!._id)[0]
      );
    }
  }, [activeChat]);

  if (recipient === null && activeChat === null) {
    return <></>;
  } else {
    return (
      <>
        <div className="header">
          <div className="btns-container left">
            <button
              className="btn optional-action"
              onClick={() => setActiveChat(null)}
            >
              <img src={BackArrow} />
            </button>
          </div>
          <h2 className="text-center fs-2">
            {recipient?.name.split(" ")[0]}{" "}
            <span className="fs-3">
              {recipient?.admin !== undefined && recipient?.admin > 0
                ? "(Moderator)"
                : ""}
            </span>
          </h2>
          <div className="btns-container right">
            <button className="btn optional-action">
              <img src={DotsIcon} />
            </button>
          </div>
        </div>
        <div className="content-container chat">
          {messages.map((message: any) => {
            return (
              <div
                className={`message-row ${
                  message.sender === currentUser?._id ? "sender" : "receipt"
                }`}
                key={message._id}
              >
                <img
                  src={
                    message.sender !== currentUser?._id
                      ? recipient?.avatar
                      : currentUser?.avatar
                  }
                  onError={handleImageError}
                  onClick={() =>
                    navigateProfile(message.sender === currentUser?._id)
                  }
                  alt="profile"
                />
                <div className="info">
                  <h2>
                    {message.sender !== currentUser?._id
                      ? recipient?.name
                      : currentUser?.name}
                  </h2>
                  <p className="stem-light">{message.message}</p>
                </div>
              </div>
            );
          })}
          {MessageSupport.message && MessageSupport.userId && (
            <div className={`message-row`}>
              <img
                src={recipient?.avatar}
                onError={handleImageError}
                alt="profile"
              />
              <div className="info">
                <h2>{recipient?.name}</h2>
                <p className="stem-light">{MessageSupport.message}</p>
              </div>
            </div>
          )}
        </div>
        {activeChat?.participants.findIndex(
          (e) => e._id === currentUser?._id
        ) !== -1 && (
          <form
            onSubmit={formik.handleSubmit}
            className="message-input-container"
          >
            <input
              type="text"
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              placeholder="Enter your message"
            />
            <button type="submit" className="btn optional-action active">
              <img src={BackArrow} className="invert-rotation" />
            </button>
          </form>
        )}
      </>
    );
  }
}

export default Chat;

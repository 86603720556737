import { useEffect, useState } from "react";
import SearchInput from "../SearchInput/SearchInput";

import { useChatContext } from "@/context/InboxContext";
import { UsersI } from "@/interfaces/Admin";
import { useUserContext } from "@/context/UserContext";
import { findUsers } from "@/services/InboxService";
import CustomSpinner from "@/components/CustomSpinner/CustomSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { handleImageError } from "@/utils/GalleryFunctions";
import ChatCard from "../ChatCard/ChatCard";

function NewMessage() {
  const { setChatView } = useChatContext();
  const { userToken } = useUserContext();

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText, setDebouncedText] = useState<string>("");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<UsersI>>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const searchUser = async (reset = false) => {
    const actualPage = reset ? 1 : page;
    findUsers(debouncedText, actualPage, userToken).then((res) => {
      if (res && res.status === 200) {
        setUsers((prev) =>
          actualPage === 1 ? res.data.users : prev.concat(res.data.users)
        );
        setTotalUsers(res.data.totalUsers);
        setPage(actualPage + 1);
        setLoadingSearch(false);
      }
    });
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText.length > 1) {
      setLoadingSearch(true);
      searchUser(true);
    } else {
      setUsers([]);
      setPage(1);
    }
  }, [debouncedText]);

  return (
    <>
      <div className="header">
        <h3 className="subtitle">New message</h3>
        <div className="btns-container right">
          <button
            className="btn optional-action w-auto px-2"
            onClick={() => setChatView(0)}
          >
            Close
          </button>
        </div>
      </div>
      <div className="content-container">
        <div className="search-container">
          <SearchInput
            value={searchText}
            setValue={setSearchText}
            placeHolder="Search by name or email address"
          />
        </div>
        <div className="search-result-container" id="searchScroll">
          {loadingSearch && (
            <CustomSpinner className="m-auto" size={8} borderWidth={4} />
          )}
          {!loadingSearch && users.length && (
            <InfiniteScroll
              scrollableTarget="searchScroll"
              next={searchUser}
              hasMore={users.length < totalUsers}
              loader={<CustomSpinner size={2} borderWidth={1} />}
              dataLength={users.length}
            >
              {users.map((user) => {
                return <ChatCard user={user} key={user._id} />;
              })}
            </InfiniteScroll>
          )}
          {!loadingSearch && users.length === 0 && (
            <h3 style={{ padding: "0px 20px" }}>
              {debouncedText.length > 1
                ? "No chats found"
                : "Search for a friend"}
            </h3>
          )}
        </div>
      </div>
    </>
  );
}

export default NewMessage;

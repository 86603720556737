import { notifyError } from "@/utils/NotificationFunctions";
import axios from "axios";
import { celebApi } from "./api";
let source: any;

export async function findUsers(
  searchText: string,
  page: number,
  token: string
) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();

  return axios
    .get(
      `${celebApi}/user/searchUserInbox?searchText=${searchText}&page=${page}`,
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function newChat(userId: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/user/newChat`, { userId }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function newMessage(messageData: any, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/user/sendNewMessage`, { messageData }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchMessages(chatId: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .get(`${celebApi}/user/fetchMessages/${chatId}`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchChats(userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .get(`${celebApi}/user/getChats`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function seenAllByChat(chatId: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(`${celebApi}/user/seenAllByChat`, { chatId }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

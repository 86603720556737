import { SetStateAction } from "react";
import "./SearchInput.scss";

interface SearchInputPropsI {
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  placeHolder: string;
}
const SearchIcon = "../../assets/images/icons/search-icon.svg";
function SearchInput(props: SearchInputPropsI) {
  const { value, setValue, placeHolder } = props;
  return (
    <div className="chat-search-bar-component-main-container">
      <label htmlFor="search">
        <img src={SearchIcon} />
      </label>
      <input
        type="text"
        className="stem-light"
        name="searchChat"
        id="searchChat"
        placeholder={placeHolder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoComplete={"off"}
      />
    </div>
  );
}

export default SearchInput;
